// variables
const menuBtn = document.querySelector(".menu-btn");
const navUlBox = document.querySelector(".nav-ul-box");

// event listeners

menuBtn.addEventListener("click", () => {
  navUlBox.classList.toggle("hidden-nav");
});

window.addEventListener("popstate", () => {
  navUlBox.classList.toggle("hidden-nav");
});

navUlBox.addEventListener("click", (e) => {
  if (!e.target.classList.contains("nav-ul-box")) return;
  navUlBox.classList.add("hidden-nav");
});

